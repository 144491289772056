import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import * as styles from './RecommendationDesktop.css';
import { Carousel } from '../Carousel';
import { UIHeading2 } from '@cian/ui-kit';
import * as classNames from 'classnames';
import { TRecommendation } from '../../types/recommendation';
import { useInView } from '@cian/valuation-utils-component';
import { Underground } from '../GeoInformation/components';

interface IRecommendationProps extends TRecommendation {
  onClick(analyticsData: object): void;
  onShowTrap(analyticsData: object): void;
  regionId: number;
  favoriteButton: React.ReactNode;
  labels: React.ReactNode;
  onTrack(offerId: number | null, position: number): void;
  position: number;
}

export const RecommendationDesktop: React.FC<IRecommendationProps> = ({
  onClick,
  onShowTrap,
  offerId,
  analyticsData,
  href,
  imageUrl,
  title: price,
  description,
  transport: address,
  underground,
  regionId,
  favoriteButton,
  labels,
  onTrack,
  position,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isHovered, setHovered] = React.useState(false);

  const mouseOver = React.useCallback(() => setHovered(true), []);
  const mouseOut = React.useCallback(() => setHovered(false), []);

  React.useEffect(() => {
    if (!offerId && inView) {
      onShowTrap(analyticsData);
    }
  }, [inView, offerId, analyticsData, onShowTrap]);

  const photos = React.useMemo(() => [{ id: 1, url: imageUrl }], [imageUrl]);

  return (
    <a
      ref={ref}
      onClick={() => onClick(analyticsData)}
      onMouseOver={mouseOver}
      onMouseLeave={mouseOut}
      className={styles['container']}
      href={href}
      target="_blank"
      rel="noreferrer"
      data-name="RecommendationDesktop"
    >
      <ActionAfterViewObserver
        percentVisible={0.5}
        callback={() => onTrack(offerId, position)}
        triggerOnce={false}
        callbackDelay={1000}
      >
        <>
          {labels}
          <span className={styles['favorite']}>{favoriteButton}</span>
          <div className={styles['gallery']}>
            <Carousel media={photos} desktopControls={isHovered} />
          </div>

          <div className={styles['info']}>
            <UIHeading2>{price}</UIHeading2>
            <a href={href} target="_blank" rel="noreferrer" className={styles['description']}>
              {description}
            </a>
            {underground && <Underground underground={underground} regionId={regionId} />}
            <span className={classNames(styles['address'], !underground && styles['two-rows'])}>{address}</span>
          </div>
        </>
      </ActionAfterViewObserver>
    </a>
  );
};
