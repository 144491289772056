import { ELandingType } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';

const Placement = {
  [ELandingType.FlatRent]: 'sale',
  [ELandingType.SecondaryFlatSale]: 'sale',
  [ELandingType.SuburbanSale]: 'suburban',
  [ELandingType.FlatRentForDay]: 'dailyrent',
  [ELandingType.MainPage]: 'mainpage',
};

export const getPageExtra = (landingType: ELandingType) => {
  return {
    lending_main_page: true,
    placement: Placement[landingType],
  };
};
