import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  isCheckedAuthentication: false,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  reducers: {
    setIsAuthenticated: state => {
      state.isAuthenticated = true;
    },
    setCheckedAuthentication: state => {
      state.isCheckedAuthentication = true;
    },
  },
  initialState,
});

export const authenticationReducer = authenticationSlice.reducer;
export const { setCheckedAuthentication, setIsAuthenticated } = authenticationSlice.actions;
