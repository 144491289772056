import * as React from 'react';
import { LinkButton, UIHeading1 } from '@cian/ui-kit';

import * as styles from './RecommendationsListDesktop.css';
import { useInView } from '@cian/valuation-utils-component';
import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';

interface IRecommendationsListDesktopProps {
  onShow?(): void;
  onMoreClick?(): void;
  children?: React.ReactNode;
}

export const RecommendationsListDesktop: React.FC<IRecommendationsListDesktopProps> = ({
  onShow,
  children,
  onMoreClick,
}) => {
  const [isShown, setIsShown] = React.useState(false);
  const { isLoaded: isVisitedOffersLoaded } = useVisitedOffersContext();

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView && onShow && !isShown && isVisitedOffersLoaded) {
      onShow();
      setIsShown(true);
    }
  }, [inView, onShow, isShown, isVisitedOffersLoaded]);

  return (
    <div ref={ref}>
      <UIHeading1>Могут подойти</UIHeading1>
      <div className={styles['list']}>{children}</div>
      <LinkButton
        data-name="MoreLink"
        size="M"
        theme="fill_secondary"
        fullWidth
        onClick={onMoreClick}
        href="/recommendations/"
      >
        Посмотреть ещё
      </LinkButton>
    </div>
  );
};
