import { ca } from '@cian/analytics';
import { ELandingType } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';
import { getPageExtra } from '../../utils/tracking';
import { TProduct } from '../../types/recommendation';

export function trackMoreButtonClick(landingType: ELandingType, modelVersion: string) {
  // @ts-expect-error Это хак связи микрофронта с мкс. Событие только на фронте.
  const CianAnalytics = window.CianAnalytics;

  if (!CianAnalytics) {
    setTimeout(() => trackMoreButtonClick(landingType, modelVersion), 5);

    return;
  }

  const page = CianAnalytics.service.config.page || {};

  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_offers_click_more',
    label: 'user-based',
    modelVersion,
    page:
      landingType !== ELandingType.MainPage
        ? {
            ...page,
            extra: getPageExtra(landingType),
          }
        : undefined,
  });
}

export const trackRecommendationsView = (landingType: ELandingType, modelVersion: string, products: TProduct[]) => {
  // @ts-expect-error Это хак связи микрофронта с мкс. Событие только на фронте. Ждем инициализации аналитики у родителя
  const CianAnalytics = window.CianAnalytics;

  if (!CianAnalytics) {
    setTimeout(() => trackRecommendationsView(landingType, modelVersion, products), 5);

    return;
  }

  const page = CianAnalytics.service.config.page || {};

  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_offers',
    label: 'user-based',
    modelVersion,
    page:
      landingType !== ELandingType.MainPage
        ? {
            ...page,
            extra: getPageExtra(landingType),
          }
        : undefined,
    products,
  });
};

export const trackRecommendationItemClick = (landingType: ELandingType, modelVersion: string, products: [TProduct]) => {
  // @ts-expect-error Это хак связи микрофронта с мкс. Событие только на фронте.
  const CianAnalytics = window.CianAnalytics;

  if (!CianAnalytics) {
    setTimeout(() => trackRecommendationItemClick(landingType, modelVersion, products), 5);

    return;
  }

  const page = CianAnalytics.service.config.page || {};

  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_similar_offers',
    label: 'user-based',
    modelVersion,
    page:
      landingType !== ELandingType.MainPage
        ? {
            ...page,
            extra: getPageExtra(landingType),
          }
        : undefined,
    products,
  });
};

export function trackEntityTeaserShow(offerId: number, position: number) {
  ca('teaser', {
    product: {
      id: offerId,
      name: 'show_card',
      extra: {
        offerType: 'offer',
      },
      position,
      screen: 'Home',
      block: 'Recommendations',
    },
  });
}
