// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IOfferUndergroundSchema } from './OfferUndergroundSchema';

export interface IRecItemV2Schema {
  /** Аналитическая информация по объекту **/
  analyticsData?: object;
  /** Тип сделки **/
  dealType?: EDealType;
  /** Тип предложения **/
  entityType?: EEntityType;
  /** Ссылка на картинку **/
  imageUrl?: string;
  /** Дополнительная информация **/
  info?: string;
  /** Находится ли данное объявление в избранном у пользователя **/
  isFavorite?: boolean;
  /** Id объявления **/
  offerId?: number;
  /** Подзаголовок **/
  subtitle?: string;
  /** Заголовок (напр. 6 800 000 ₽) **/
  title?: string;
  underground?: IOfferUndergroundSchema;
  /** Ссылка на объявление **/
  url: string;
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EEntityType {
  /** Коммерческая **/
  'OfferCommercial' = 'offerCommercial',
  /** Жилая **/
  'OfferResidental' = 'offerResidental',
  /** Загородка **/
  'OfferSuburban' = 'offerSuburban',
}
