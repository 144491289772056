// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IAddFavoriteRequest,
  TAddFavoriteModel,
  IMappers,
  TAddFavoriteResponse,
  IAddFavoriteResponse,
  IAddFavoriteResponseError,
} from './types';

export const defaultConfig: TAddFavoriteModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'favorites',
  pathApi: '/v1/add-favorite/',
  requestType: 'json',
} as TAddFavoriteModel;

export function createAddFavoriteModel(parameters: IAddFavoriteRequest): TAddFavoriteModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddFavoriteOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IAddFavoriteRequest;
}

export async function fetchAddFavorite<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IAddFavoriteOptions<TResponse200, TResponse400>): Promise<TAddFavoriteResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createAddFavoriteModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAddFavoriteResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IAddFavoriteResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TAddFavoriteResponse;
}
