import { UIText1 } from '@cian/ui-kit/typography';
import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './Underground.css';
import { UndergroundIcon } from './components/UndergroundIcon';
import { GeoTravelTime } from '../GeoTravelTime';
import { IOfferUndergroundSchema } from '../../../../types/geo';

export interface IUndergroundProps {
  regionId: number;
  underground: IOfferUndergroundSchema;
}

export const Underground: React.FC<IUndergroundProps> = ({ regionId, underground }) => {
  // TODO: пока в схеме нет поля `underConstruction`, но будет добавлено
  const { lineColor, name, time, transportType /* , underConstruction */ } = underground;

  return (
    <div className={classNames(styles['container'] /* , underConstruction && styles['under-construction'] */)}>
      {lineColor && <UndergroundIcon className={styles['icon']} color={`#${lineColor}`} regionId={[regionId]} />}
      <span className={styles['name']}>
        <UIText1 display="inline">{name}</UIText1>
      </span>
      {time && transportType && <GeoTravelTime time={time} transportType={transportType} />}
    </div>
  );
};
