import { TAddFavoriteResponse, fetchAddFavorite } from '../../repositories/favorites/v1/add-favorite';
import {
  EDealType,
  EEntityType,
} from '../../repositories/favorites/entities/add_favorite/AddFavoriteEntityPublicRequestSchema';
import { IApplicationContext } from '../../types';
import { ResponseError } from '../../errors';
import { IAddFavoriteResponseSchema } from '../../repositories/favorites/entities/add_favorite/AddFavoriteResponseSchema';

export type TAddToFavoriteParameters = {
  offerId: number;
  dealType: EDealType;
  entityType: EEntityType;
};

export async function addToFavorite(
  context: IApplicationContext,
  parameters: TAddToFavoriteParameters,
): Promise<IAddFavoriteResponseSchema> {
  const { logger, httpApi } = context;
  const { offerId, dealType, entityType } = parameters;

  try {
    const response: TAddFavoriteResponse = await fetchAddFavorite({
      httpApi,
      parameters: {
        entityId: offerId,
        dealType,
        entityType,
        addToFolder: true,
      },
    });

    if (response.statusCode !== 200) {
      /* istanbul ignore next */
      throw new ResponseError({
        domain: 'addToFavorite',
        message: response.response.message || 'Код ответа не 200',
        details: { errors: JSON.stringify(response.response.errors) },
      });
    }

    if (!response.response.isAdded) {
      throw new ResponseError({
        domain: 'addToFavorite',
        message: 'Не добавлено в избранное',
        details: { response: JSON.stringify(response.response) },
      });
    }

    return response.response;
  } catch (error) {
    logger.error(error, {
      offerId,
      domain: 'addToFavorite',
    });

    throw error;
  }
}
