import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import * as React from 'react';

import { TgbNativedescGeneral } from './TgbNativedescGeneral';

type TTgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: React.FC<TTgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const { annotation, image, url, description, title, label } = context;

  return (
    <TgbNativedescGeneral
      url={url}
      label={label}
      image={image}
      annotation={annotation}
      title={title}
      description={description}
    />
  );
};
