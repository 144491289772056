import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TPParams } from '../../../../../types';

import type { TAdfoxContextData } from '../../types';
import { selectPuids } from '../../../../../../../selectors/selectPuids';

type Params = TAdfoxContextData['params'];

export const PR_RANDOM = getRandomId();

export function getRandomId() {
  return String(Math.floor(Math.random() * 4294967295) + 1);
}

export function useAdfoxParams(pParams: TPParams): Params | null {
  const puids = useSelector(selectPuids);

  return useMemo((): Params | null => {
    if (!puids) {
      return null;
    }

    return {
      pr: PR_RANDOM,
      ...puids,
      ...pParams,
    };
  }, [pParams, puids]);
}
