import { RoundButton } from '@cian/ui-kit/button';
import { IconToggleHeartOff16, IconToggleHeartOn16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export interface IFavoriteButtonProps {
  size: 'XS' | 'M';
  isActive: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const FavoriteButton: React.FC<IFavoriteButtonProps> = ({ isActive, onClick, size }) => {
  return (
    <RoundButton
      icon={
        isActive ? (
          // FIXME: Грязный хак, чтобы `RoundButton` не перетирал `color`
          <span>
            <IconToggleHeartOn16 color="accent-negative-primary" aria-label="heartActive" display="block" />
          </span>
        ) : (
          <IconToggleHeartOff16 color="decorative-theme-white" aria-label="heart" display="block" />
        )
      }
      size={size}
      title="Избранное"
      theme="fill_dark"
      onClick={onClick}
    />
  );
};
