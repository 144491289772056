import { WebComponent } from '@cian/mf-registry-legacy/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { Application } from '../../../shared/containers/App';
import { IApplicationContext, IReduxStore } from '../../../shared/types';

export function createRecommendationsConstructor(
  context: IApplicationContext,
  store: IReduxStore,
): CustomElementConstructor {
  class Recommendations extends WebComponent {
    private reactRoot: ReactDOM.Root;

    public constructor() {
      super();
    }

    protected willUnmount() {
      super.willUnmount();

      this.reactRoot.unmount();
    }

    public render() {
      const reactElement = <Application context={context} reduxStore={store} />;

      if (this.shouldHydrate) {
        this.reactRoot = ReactDOM.hydrateRoot(this.root, reactElement);
      } else {
        this.reactRoot = ReactDOM.createRoot(this.root);
        this.reactRoot.render(reactElement);
      }
    }
  }

  return Recommendations;
}
