// eslint-disable-next-line import/no-extraneous-dependencies
import { Scroller, Slider, Image, Overlay, Buttons } from '@cian/slider';
import * as React from 'react';

import { PaginationDots } from './components/PaginationDots';
import { IOfferPhoto } from '../../types/recommendation';

interface ICarouselProps {
  desktopControls?: boolean;
  media: IOfferPhoto[];
  onSlideChange?(index: number): void;
}

export const Carousel: React.FC<ICarouselProps> = ({ desktopControls, media: mediaRaw, onSlideChange }) => {
  const showButtons = React.useMemo(() => mediaRaw.length > 1, [mediaRaw]);

  return (
    <Slider onSlideChange={onSlideChange}>
      <Scroller>
        {mediaRaw.map(media => (
          <Image key={media.id} src={media.url} />
        ))}
      </Scroller>
      <Overlay hideable={false}>
        {desktopControls && showButtons && <Buttons />}
        {showButtons && <PaginationDots />}
      </Overlay>
    </Slider>
  );
};
