/* istanbul ignore file */

/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-218072, будет удалён в задаче CD-218443
 */

import { useSelector } from 'react-redux';
import { useApplicationContext } from '../utils/applicationContext';
import { selectExperiments } from './selectExperiments';
import { isAbUseExperimentGroup } from '../utils/isAbUseExperimentGroup';
import { selectRegionId } from './selectRegionId';

export const AUD_MOBILE_NB1796_FEED_ADFOX_NEWBANNER_EXPERIMENT = 'aud_mobile_nb1796_feed_adfox_newbanner';

export function useSelectAudMobileNb1796FeedAdfoxNewbannerExperimentEnabled(): boolean {
  const { config } = useApplicationContext();

  const regionId = useSelector(selectRegionId);

  const abUseExperiments = useSelector(selectExperiments);

  return (
    !!config.get<boolean>('AudMobileNb1796FeedAdfoxNewbanner.MobWebMainPage.Enabled') &&
    [1, 4593, -1].includes(regionId) &&
    isAbUseExperimentGroup(abUseExperiments, AUD_MOBILE_NB1796_FEED_ADFOX_NEWBANNER_EXPERIMENT, 'B')
  );
}
