import { createSlice } from '@reduxjs/toolkit';

import { TGetMultipleExperimentResponse_1 } from '../../repositories/ab-use/v1/get-multiple-experiment';

const initialState: TGetMultipleExperimentResponse_1['result'] = [];

export const { reducer: experimentsReducer } = createSlice({
  name: 'experiments',
  reducers: {},
  initialState,
});
