import { IApplicationContext } from '../../../shared/types';
import { getConfig } from '@cian/config/browser';
import { TDeviceType } from '@cian/ui-kit/types';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

export function createApplicationContext(custom: IApplicationContext['custom']): IApplicationContext {
  const config = getConfig();

  return {
    config,
    custom: {
      ...custom,
      initialDeviceType: config.get<TDeviceType>('initialDeviceType') || 'desktop',
      isMobile: config.get<boolean>('isMobile') || false,
      subdomain: config.get<string>('subdomain') || 'www',
    },
    httpApi: getHttpApi(),
    logger: getLogger(),
    telemetry: getTelemetry(),
  };
}
