import * as React from 'react';

import * as styles from './Dot.css';
import * as classNames from 'classnames';

interface IDotProps {
  active?: boolean;
  size: 'S' | 'M' | 'L';
}

const DotComponent: React.FC<IDotProps> = ({ active, size }) => {
  return (
    <li
      className={classNames(
        styles['container'],
        styles[`container--size-${size.toLowerCase()}`],
        active && styles['container--active'],
      )}
    />
  );
};

export const Dot = React.memo(DotComponent);
