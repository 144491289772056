import { showOnBoardingDelete } from '@cian/favorites-assign-widget';
import {
  TDeleteFromFavoriteParameters,
  deleteFromFavorite as _deleteFromFavorite,
} from '../services/deleteFromFavorite';
import { trackDeleteFromFavorite } from './tracking';
import { EEntityType } from '../repositories/feed-page/entities/recommendations/RecItemV2Schema';
import { createAppAsyncThunk } from '../utils/redux/createAppAsyncThunk';
import { TOfferAnalyticsData, TRecommendation } from '../types/recommendation';
import { TDeviceType } from '@cian/ui-kit';
import { toggleFavorite } from '../slices';

type TDeleteFromFavoriteThunkParameters = {
  deviceType: TDeviceType;
  recommendation: TRecommendation;
};

export const deleteFromFavorite = createAppAsyncThunk(
  'recommendations/deleteFromFavorite',
  async (
    { recommendation, deviceType }: TDeleteFromFavoriteThunkParameters,
    { getState, extra: context, dispatch },
  ) => {
    dispatch(toggleFavorite({ offerId: recommendation.offerId as number, isFavorite: false }));

    const { offerId, dealType, entityType, analyticsData } = recommendation;

    await _deleteFromFavorite(context, { offerId, dealType, entityType } as TDeleteFromFavoriteParameters);

    const label = `${recommendation.dealType}/${
      recommendation.entityType === EEntityType.OfferSuburban ? 'suburban' : 'flat'
    }`;
    const { landingType } = getState();

    trackDeleteFromFavorite({
      deviceType,
      label,
      landingType,
      products: (analyticsData as TOfferAnalyticsData).products,
    });

    dispatch(showOnBoardingDelete());
  },
);
