import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRecommendation } from '../../types/recommendation';
import { getMobileRecommendations, getDesktopRecommendations } from '../../services/getRecommendations';
import { ELoadingStatus } from '../../types/loadingStatus';
import { createAppAsyncThunk } from '../../utils/redux/createAppAsyncThunk';

const initialState = {
  data: [] as TRecommendation[],
  status: ELoadingStatus.Loading,
};

export const fetchRecommendations = createAppAsyncThunk('recommendations/fetch', (_, { getState, extra: context }) => {
  const {
    custom: { isMobile },
  } = context;
  const { regionId, landingType } = getState();

  const getRecommendations = isMobile ? getMobileRecommendations : getDesktopRecommendations;

  return getRecommendations(context, { parameters: { regionId, landingType } });
});

type TToggleFavoritePayload = {
  offerId: number;
  isFavorite: boolean;
};

const recommendationsSlice = createSlice({
  name: 'recommendations',
  reducers: {
    toggleFavorite: (state, action: PayloadAction<TToggleFavoritePayload>) => {
      state.data.forEach(recommendation => {
        if (recommendation.offerId === action.payload.offerId) {
          recommendation.isFavorite = action.payload.isFavorite;
        }
      });
    },
  },
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchRecommendations.pending, state => {
      state.status = ELoadingStatus.Loading;
    });
    builder.addCase(fetchRecommendations.fulfilled, (state, { payload }) => {
      state.status = ELoadingStatus.Success;
      state.data = payload;
    });
    builder.addCase(fetchRecommendations.rejected, state => {
      state.status = ELoadingStatus.Failure;
    });
  },
});

export const recommendationsReducer = recommendationsSlice.reducer;
export const { toggleFavorite } = recommendationsSlice.actions;
