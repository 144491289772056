import {
  IOfferUndergroundSchema,
  ETransportType,
} from '../repositories/feed-page/entities/recommendations/OfferUndergroundSchema';

export type { IOfferUndergroundSchema };

export { ETransportType };

export interface IAddressDetailSchema {
  /** Название **/
  key: string;
  /** Значение параметра **/
  value: string;
}

export interface IHighwaySchema {
  /** Расстояние от МКАД **/
  distance?: string | null;
  /** ID шоссе **/
  id: number;
  /** Название станции **/
  name: string;
}

export interface IRailwaySchema {
  /** Список ID направлений **/
  directionIds?: number[] | null;
  /** Расстояние до станции **/
  distance?: number | null;
  /** ID ЖД-станции **/
  id?: number | null;
  /** Название станции **/
  name?: string | null;
  /** Время до станции **/
  time?: string | null;
  /** Способ передвижения до ЖД **/
  travelType?: ETravelType | null;
}

export enum ETravelType {
  /** На машине **/
  ByCar = 'byCar',
  /** Пешком **/
  ByFoot = 'byFoot',
}

export interface IGeoSchema {
  regionId: number;
  /** Список объектов составляющих адрес **/
  address: IAddressDetailSchema[];
  /** Список шоссе **/
  highways: IHighwaySchema[];
  /** Список станций метро **/
  undergrounds?: IOfferUndergroundSchema[];
  // поезда ждя пригородки
  railways?: IRailwaySchema[];
}
