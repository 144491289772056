import * as React from 'react';

import * as styles from './UndergroundIcon.css';
import { getRegionUndergroundIcon } from './utils/getRegionUndergroundIcon';
import * as classNames from 'classnames';

interface IUndergroundIconProps {
  className?: string;
  color: Exclude<React.CSSProperties['color'], undefined>;
  regionId: number[];
}

export const UndergroundIcon: React.FC<IUndergroundIconProps> = ({ className, color, regionId }) => {
  const Icon = React.useMemo(() => getRegionUndergroundIcon(regionId), [regionId]);

  return (
    <div className={classNames(styles['container'], className)} style={{ color }}>
      <Icon display="block" color="current_color" />
    </div>
  );
};
