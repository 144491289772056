import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const showHeaderSlice = createSlice({
  name: 'showHeader',
  reducers: {},
  initialState,
});

export const showHeaderReducer = showHeaderSlice.reducer;
