/*
  Возвращаемый хэлпер createAppAsyncThunk косвенно зависит от createSlice, в итоге
  в рантайме падает ошибка попытки чтения undefined значения.

  Импортировать такой хэлпер нужно только напрямую, т.е:

  import { createAppAsyncThunk } from '../../utils/redux/createAppAsyncThunk';

  иначе вернемся к той же ошибке.
 */
//  @ts-expect-error cм. коммент выше
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAppState, IApplicationContext } from '../../types';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: IAppState;
  extra: IApplicationContext;
}>();
