import * as React from 'react';
import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitRoot } from '@cian/ui-kit';
import { Provider } from 'react-redux';
import { IVisitedOffersProviderProps, VisitedOffersProvider } from '@cian/frontend-visited-offers-widget';

import { IApplicationContext, IReduxStore } from '../../types';
import { ApplicationContext } from '../../utils/applicationContext';
import { RecommendationsListContainer } from '../RecommendationsListContainer';
import { FavoritesAssignWidgetContainer } from '../FavoritesAssignWidgetContainer';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { initialDeviceType },
    config,
  } = context;

  const { settings, enabled } = config.get<IVisitedOffersProviderProps>('audience.visitedOffers') || {};

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitRoot deviceType={initialDeviceType}>
          <Provider store={reduxStore}>
            <VisitedOffersProvider settings={settings} enabled={enabled}>
              <RecommendationsListContainer />
              <FavoritesAssignWidgetContainer />
            </VisitedOffersProvider>
          </Provider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
