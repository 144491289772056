import { TGetMultipleExperimentResponse_1 } from '../repositories/ab-use/v1/get-multiple-experiment';

export function isAbUseExperimentGroup(
  experiments: TGetMultipleExperimentResponse_1['result'],
  experimentName: string,
  targetGroup: string,
) {
  const experiment = experiments.find(e => e.experimentName === experimentName);

  if (experiment) {
    return experiment.groupName === targetGroup;
  }

  return false;
}
