import { ScrollFade, Ticker } from '@cian/adfox-templates';
import { Label, UIHeading5, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './TgbNativedescGeneral.css';

export type TgbNativedescGeneralProps = {
  url: string;
  image: string;
  title: string;
  annotation: string;
  label: string | undefined;
  description: string | undefined;
};

export const TgbNativedescGeneral: React.FC<TgbNativedescGeneralProps> = props => {
  const { title, annotation, image, url, label, description } = props;

  return (
    <a
      data-testid="TgbNativedescGeneral"
      href={url}
      target="_blank"
      className={styles['container']}
      rel="noopener noreferrer"
    >
      <div className={styles['photo-block']} style={{ backgroundImage: `url(${image})` }}>
        {!!label && (
          <div className={styles['photo-label']}>
            <Label color="decorative-theme-white" background="overlay-default">
              {label}
            </Label>
          </div>
        )}
        {!!annotation && (
          <div className={styles['annotation']}>
            <Ticker text={annotation} withBackground />
          </div>
        )}
      </div>
      <div className={styles['content']}>
        <UIHeading5>{title}</UIHeading5>
        <div className={styles['description-container']}>
          <UIText3>{description}</UIText3>
          <ScrollFade size={12} />
        </div>
      </div>
    </a>
  );
};
