import { IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useBooleanState } from '@cian/react-utils';
import { clone } from 'ramda';
import * as React from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

type TAdfoxBannerContainerProps = {
  onError?: (error: IAdFoxBannerOnErrorError) => void;
  onStub?: () => void;
  onRender?: () => void;
  position?: number;
};

type THandleError = (error: IAdFoxBannerOnErrorError) => void;

export const AdfoxBannerContainer = React.memo<TAdfoxBannerContainerProps>(function AdfoxBannerContainer(props) {
  const { onStub, onError, onRender, position } = props;

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();
  const { params, size, analyticsData } = useAdfoxContext();

  const handleStub = React.useCallback<VoidFunction>(() => {
    onStub?.();

    setBannerHidden();
  }, [onStub, setBannerHidden]);

  const handleError = React.useCallback<THandleError>(
    error => {
      onError?.(error);

      setBannerHidden();
    },
    [onError, setBannerHidden],
  );

  return React.useMemo<JSX.Element | null>(() => {
    if (isBannerHidden) {
      return null;
    }

    let postviewAnalyticsData = analyticsData;

    if (position != null) {
      postviewAnalyticsData = clone(analyticsData);

      postviewAnalyticsData.banner.position = position;
    }

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(
      AdfoxBanner,
      {
        lazyLoad: { fetchMargin: 100, mobileScaling: 1 },
        params,
        onError: handleError,
        onStub: handleStub,
        onRender,
        ...size,
      },
      postviewAnalyticsData,
    );

    return <AdfoxWithPostView />;
  }, [analyticsData, handleError, handleStub, isBannerHidden, onRender, params, position, size]);
});
