// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IModelResponse, IModel } from '@cian/http-api';
import type { IGetRecommendationsResponseV2Schema } from '../../../entities/recommendations/GetRecommendationsResponseV2Schema';
import type { IErrorResponseSchema } from '../../../entities/schemas/ErrorResponseSchema';

export interface IMobileSiteGetRecommendationsRequest {
  /** Id региона пользователя **/
  regionId: number;
  /** Тип лэндинга **/
  landingType: ELandingType;
}
export enum ELandingType {
  /** Снять — квартиру на долгий срок **/
  'FlatRent' = 'flatRent',
  /** Посуточно — снять квартиру посуточно **/
  'FlatRentForDay' = 'flatRentForDay',
  /** Главная cian.ru **/
  'MainPage' = 'mainPage',
  /** Купить — квартиру во вторичке **/
  'SecondaryFlatSale' = 'secondaryFlatSale',
  /** Загородка — купить дом, дачу, таунхаус, участок **/
  'SuburbanSale' = 'suburbanSale',
}

export type IMobileSiteGetRecommendationsResponse = IGetRecommendationsResponseV2Schema;

export interface IMobileSiteGetRecommendationsResponse200
  extends IModelResponse<IMobileSiteGetRecommendationsResponse> {
  statusCode: 200;
}

export type IMobileSiteGetRecommendationsResponseError = IErrorResponseSchema;

export interface IMobileSiteGetRecommendationsResponse400
  extends IModelResponse<IMobileSiteGetRecommendationsResponseError> {
  statusCode: 400;
}

export type TMobileSiteGetRecommendationsResponse =
  | IMobileSiteGetRecommendationsResponse200
  | IMobileSiteGetRecommendationsResponse400;

export type TMobileSiteGetRecommendationsModel = IModel<
  IMobileSiteGetRecommendationsRequest,
  TMobileSiteGetRecommendationsResponse
>;

export interface IMappers<TResponse200, TResponse400> {
  200(response: IMobileSiteGetRecommendationsResponse): TResponse200;
  400(response: IMobileSiteGetRecommendationsResponseError): TResponse400;
}
