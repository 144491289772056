import { IAppState } from '../../types/redux';

export function selectRecommendations(state: IAppState) {
  return state.recommendations.data;
}

export const selectRecommendationById = (id: number | null) => (state: IAppState) =>
  state.recommendations.data.find(({ offerId }) => offerId === id) || null;

export function selectRecommendationsStatus(state: IAppState) {
  return state.recommendations.status;
}
