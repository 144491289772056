import { getMicrofrontend } from '@cian/mf-registry-legacy/browser';
import { createRecommendationsConstructor } from '../services/WebComponent/createRecommendationsConstructor';
import { methods } from './methods';
import { createApplicationContext } from '../services/applicationContext/createApplicationContext';
import { IAppState, IApplicationContext } from '../../shared/types';
import { createReduxStore } from '../../shared/utils/redux';

export function start() {
  const context = createApplicationContext({} as IApplicationContext['custom']);

  const { config } = context;
  const initialState = config.getStrict<IAppState>('initialState');

  const store = createReduxStore(context, initialState, true);
  const constructor = createRecommendationsConstructor(context, store);

  getMicrofrontend().start({
    runtimes: {
      ui: {
        constructor,
      },
      api: {
        context: {
          store,
          httpApi: context.httpApi,
          logger: context.logger,
        },
        methods,
      },
    },
  });
}
