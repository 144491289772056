import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createReduxStore } from '../../utils/redux';

export type IReduxStore = ReturnType<typeof createReduxStore>;
export type IAppState = ReturnType<IReduxStore['getState']>;
export type TDispatch = IReduxStore['dispatch'];
export type TGetState = IReduxStore['getState'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => TDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IAppState> = useSelector;
