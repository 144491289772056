import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';
import { Recommendation } from '../../../components/Recommendation';
import { FavoriteButtonContainer } from '../../FavoriteButtonContainer';
import { LabelsContainer } from '../../LabelsContainer';
import { ELandingType } from '../../../repositories/feed-page/v2/mobile-site/get-recommendations';
import { AdfoxBannerMainPageOfferMobile } from '../../../components/AdfoxBanner';
import { RecommendationsList } from '../../../components/RecommendationsList';

import { useAppSelector as useSelector } from '../../../types';
import { selectLandingType } from '../../../selectors/landingType';
import type { useRecommendations } from '../useRecommendations';
import { useSelectAudMobileNb1796FeedAdfoxNewbannerExperimentEnabled } from '../../../selectors/useSelectAudMobileNb1796FeedAdfoxNewbannerExperimentEnabled';

type TRecommendationsListMobileProps = {
  onTrack: (offerId: number | null, position: number) => void;
  recommendationsControls: ReturnType<typeof useRecommendations>;
};

export const RecommendationsListMobile: React.FC<TRecommendationsListMobileProps> = props => {
  const {
    recommendationsControls: {
      onShow,
      showHeader,
      onMoreClick,
      recommendations,
      onRecommendationClick,
      onShowTrap,
      isOfferVisited,
    },
    onTrack,
  } = props;

  const landingType = useSelector(selectLandingType);

  /**
   * @todo Удалить строку с экспериментом
   * @description Данный функционал появился в задаче CD-218072, будет удалён в задаче CD-218443
   */
  const audMobileNb1796FeedAdfoxNewbannerExperimentEnabled =
    useSelectAudMobileNb1796FeedAdfoxNewbannerExperimentEnabled();

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();

  return (
    <RecommendationsList onShow={onShow} onMoreClick={onMoreClick} withHeader={showHeader}>
      {recommendations.reduce((accumulator, recommendation, index, array) => {
        /**
         * @todo Удалить логику с экспериментом
         * @description Данный функционал появился в задаче CD-218072, будет удалён в задаче CD-218443
         * Если эксперимент будет не удачным, то удалить удалить ветвление полностью
         * Если эксперимент будет удачным, то удалить комментарий
         */
        if (accumulator.length >= array.length) {
          return accumulator;
        }

        const getPosition = (): number => accumulator.length + 1;

        accumulator.push(
          <Recommendation
            onTrack={onTrack}
            position={getPosition()}
            key={`recommendation-${index}`}
            onClick={onRecommendationClick}
            onShowTrap={onShowTrap}
            favoriteButton={<FavoriteButtonContainer size="XS" id={recommendation.offerId} />}
            labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
            {...recommendation}
          />,
        );

        /**
         * @todo Удалить логику с экспериментом
         * @description Данный функционал появился в задаче CD-218072, будет удалён в задаче CD-218443
         * Если эксперимент будет не удачным, то удалить удалить ветвление полностью
         * Если эксперимент будет удачным, то удалить условие эксперимента
         */
        if (
          audMobileNb1796FeedAdfoxNewbannerExperimentEnabled &&
          !isBannerHidden &&
          landingType === ELandingType.MainPage &&
          getPosition() === 2
        ) {
          accumulator.push(
            <AdfoxBannerMainPageOfferMobile
              onStub={setBannerHidden}
              onError={setBannerHidden}
              key={`adfox-banner-${index}`}
              position={getPosition()}
            />,
          );
        }

        return accumulator;
      }, Array.of<JSX.Element>())}
    </RecommendationsList>
  );
};
