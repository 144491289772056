// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IMobileSiteGetRecommendationsRequest,
  TMobileSiteGetRecommendationsModel,
  IMappers,
  TMobileSiteGetRecommendationsResponse,
  IMobileSiteGetRecommendationsResponse,
  IMobileSiteGetRecommendationsResponseError,
} from './types';

export const defaultConfig: TMobileSiteGetRecommendationsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'feed-page',
  pathApi: '/v2/mobile-site/get-recommendations/',
} as TMobileSiteGetRecommendationsModel;

export function createMobileSiteGetRecommendationsModel(
  parameters: IMobileSiteGetRecommendationsRequest,
): TMobileSiteGetRecommendationsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IMobileSiteGetRecommendationsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IMobileSiteGetRecommendationsRequest;
}

export async function fetchMobileSiteGetRecommendations<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IMobileSiteGetRecommendationsOptions<TResponse200, TResponse400>): Promise<
  TMobileSiteGetRecommendationsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createMobileSiteGetRecommendationsModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IMobileSiteGetRecommendationsResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IMobileSiteGetRecommendationsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TMobileSiteGetRecommendationsResponse;
}
