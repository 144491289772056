import { makeContextModule } from '@cian/react-utils';
import * as React from 'react';

import { useAdfoxParams } from './internal/hooks/useAdfoxParams';
import { TPParams } from '../../../types';

import type { TAdfoxContextData } from './types';

const contextData = makeContextModule<TAdfoxContextData>({ contextName: 'AdfoxContextData' });

const {
  Context: { Provider },
} = contextData;

export const { useContext: useAdfoxContext, withContext: withAdfoxContext, Context: AdfoxContext } = contextData;

export type AdfoxContextProviderProps = Omit<TAdfoxContextData, 'params' | 'isHidden'> & {
  children: JSX.Element | JSX.Element[];
  pParams: TPParams;
};

export const AdfoxContextProvider: React.FC<AdfoxContextProviderProps> = props => {
  const { children, pParams, size, analyticsData } = props;

  const params = useAdfoxParams(pParams);

  const contextValue = React.useMemo((): TAdfoxContextData | null => {
    if (!params) {
      return null;
    }

    return {
      params,
      size,
      analyticsData,
    };
  }, [params, analyticsData, size]);

  return contextValue && <Provider value={contextValue}>{children}</Provider>;
};
