import { ResponseError } from '../../errors';
import { prepareRecommendations } from '../../mappers';
import {
  fetchDesktopGetRecommendations,
  TDesktopGetRecommendationsResponse,
  IDesktopGetRecommendationsRequest,
} from '../../repositories/feed-page/v2/desktop/get-recommendations';
import { IApplicationContext } from '../../types/applicationContext';
import { TRecommendation } from '../../types/recommendation';

const DEGRADATION_TELEMETRY_KEY = 'degradation.recommendations.desktop';

type TGetRecommendationsParams = {
  parameters: IDesktopGetRecommendationsRequest;
  cookies?: string;
};

export async function getDesktopRecommendations(
  context: IApplicationContext,
  { parameters, cookies }: TGetRecommendationsParams,
): Promise<TRecommendation[]> {
  const { httpApi, logger, telemetry } = context;

  try {
    const response: TDesktopGetRecommendationsResponse = await fetchDesktopGetRecommendations({
      httpApi,
      parameters,
      config: {
        requestConfig: {
          headers: cookies ? [['Cookie', cookies]] : [],
        },
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getDesktopRecommendations',
        message: response.response.message || 'Код ответа не 200',
        details: { errors: JSON.stringify(response.response.errors) },
      });
    }

    return prepareRecommendations(response.response.recommendations);
  } catch (error) {
    telemetry.increment(DEGRADATION_TELEMETRY_KEY);
    logger.warning(error, {
      domain: 'getDesktopRecommendations',
      message: 'Не удалось получить список рекомендаций',
    });

    // Повторное выкидывание исключения нужно для asyncThunk
    throw error;
  }
}
