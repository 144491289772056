// eslint-disable-next-line import/no-extraneous-dependencies
import { useSliderContext } from '@cian/slider';
import * as React from 'react';

import * as styles from './PaginationDots.css';
import { Dot } from './components/Dot';
import { MAX_DOTS_HALF_COUNT, DOT_WIDTH, MAX_DOTS_COUNT } from './constants';

type TDotProps = React.ComponentProps<typeof Dot>;

export const PaginationDots: React.FC = () => {
  const { currentSlide, totalSlides } = useSliderContext();
  const { container: containerStyle, scroller: scrollerStyle } = React.useMemo<{
    container: React.CSSProperties;
    scroller: React.CSSProperties;
  }>(() => {
    const container: React.CSSProperties = {};
    const scroller: React.CSSProperties = {};

    const paddingRight = Math.min(
      Math.max(0, MAX_DOTS_HALF_COUNT - 1 + (currentSlide - (totalSlides - 1))),
      Math.max(0, totalSlides - (MAX_DOTS_COUNT - 2)),
    );

    {
      const isDynamic = totalSlides > 5;
      const paddingLeft = isDynamic ? Math.max(0, MAX_DOTS_HALF_COUNT - 1 - currentSlide) : 0;
      const centeringCompensation = !paddingLeft ? Math.abs(Math.min(0, totalSlides - MAX_DOTS_COUNT)) / 2 : 0;

      const translateX = ((-paddingLeft + paddingRight) / 2 + centeringCompensation) * DOT_WIDTH;

      container['paddingLeft'] = paddingLeft > 0 ? `${paddingLeft * DOT_WIDTH}px` : undefined;
      container['transform'] = translateX ? `translateX(calc(-50% + ${translateX}px))` : undefined;
    }

    {
      const slidingShift = Math.min(
        Math.max(0, currentSlide - MAX_DOTS_HALF_COUNT),
        Math.max(0, totalSlides - MAX_DOTS_COUNT),
      );

      const translateX = (-slidingShift + -paddingRight) * DOT_WIDTH;

      scroller['transform'] = translateX ? `translateX(${translateX}px)` : undefined;
    }

    return { container, scroller };
  }, [currentSlide, totalSlides]);
  const dotsConfig = React.useMemo(() => prepareDots(totalSlides, currentSlide), [currentSlide, totalSlides]);

  return (
    <div className={styles['container']} style={containerStyle}>
      <ul className={styles['scroller']} style={scrollerStyle}>
        {dotsConfig.map((c, i) => (
          <Dot key={`dot_${i}`} {...c} />
        ))}
      </ul>
    </div>
  );
};

function prepareDots(totalSlides: number, currentSlide: number): TDotProps[] {
  const isPaginationBeginning = currentSlide < MAX_DOTS_HALF_COUNT;
  const isPaginationEnds = currentSlide > totalSlides - MAX_DOTS_HALF_COUNT - 1;
  const isAtPaginationCenter = !isPaginationBeginning && !isPaginationEnds;

  return new Array(totalSlides).fill(null).map<TDotProps>((_, i) => {
    if (totalSlides <= 5) {
      return { size: 'L', active: i === currentSlide };
    }

    const dotIndexDiff = Math.abs(currentSlide - i);
    const isDotTrailing = i < currentSlide;
    const isDotLeading = i > currentSlide;

    let size: TDotProps['size'] = 'M';
    switch (true) {
      case dotIndexDiff > 3:
      case isPaginationBeginning && isDotTrailing && dotIndexDiff > 1:
      case isPaginationEnds && isDotLeading && dotIndexDiff > 1:
      case isAtPaginationCenter && dotIndexDiff > 2:
        size = 'S';
        break;
      case i === currentSlide:
      case isPaginationBeginning && isDotLeading && dotIndexDiff <= 2:
      case isPaginationEnds && isDotTrailing && dotIndexDiff <= 2:
      case isAtPaginationCenter && dotIndexDiff === 1:
        size = 'L';
        break;
    }

    return { size, active: i === currentSlide };
  });
}
