import * as React from 'react';
import { FavoriteButtonWrapperContainer } from '@cian/favorites-assign-widget';
import { useDeviceType } from '@cian/ui-kit';

import { FavoriteButton } from '../../components/FavoriteButton';
import { useAppDispatch, useAppSelector as useSelector } from '../../types';
import { selectRecommendationById } from '../../selectors/recommendations';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectIsAuthenticated } from '../../selectors/isAuthenticated';
import { addToFavorite, deleteFromFavorite, requestAuthentication } from '../../thunks';

interface IFavoriteButtonContainerProps {
  id: number | null;
  size: 'XS' | 'M';
}

export const FavoriteButtonContainer: React.FC<IFavoriteButtonContainerProps> = ({ id, size }) => {
  const dispatch = useAppDispatch();
  const recommendation = useSelector(selectRecommendationById(id));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const {
    custom: { isMobile },
  } = useApplicationContext();
  const deviceType = useDeviceType();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (!recommendation) {
        return;
      }

      const action = recommendation.isFavorite ? deleteFromFavorite : addToFavorite;

      dispatch(action({ recommendation, deviceType }));
    },
    [dispatch, recommendation, deviceType],
  );

  const handleLogin = () => {
    dispatch(requestAuthentication({}));

    return false;
  };

  if (!id || !recommendation) {
    return null;
  }

  const button = <FavoriteButton size={size} onClick={handleClick} isActive={recommendation.isFavorite} />;

  return isMobile ? (
    button
  ) : (
    <FavoriteButtonWrapperContainer
      offerId={id}
      placement="bottom-end"
      isAuthenticated={isAuthenticated}
      onWaitForLogin={handleLogin}
    >
      {button}
    </FavoriteButtonWrapperContainer>
  );
};
