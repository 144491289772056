import {
  IconMetroMsk16,
  IconMetroSpb16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroNN16,
  IconMetroNovosib16,
  IconMetroSam16,
} from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit-design-tokens/internal';
import * as React from 'react';

const REGION_METRO_ICON_MAP = new Map<number, React.ComponentType<IIconProps>>([
  [1, IconMetroMsk16],
  [2, IconMetroSpb16],
  [4612, IconMetroEkb16],
  [4618, IconMetroKzn16],
  [4596, IconMetroNN16],
  [4598, IconMetroNovosib16],
  [4608, IconMetroSam16],
]);

export function getRegionUndergroundIcon(regionId: number[]): React.ComponentType<IIconProps> {
  const availableRegionId = regionId.find(id => REGION_METRO_ICON_MAP.has(id));

  return (availableRegionId && REGION_METRO_ICON_MAP.get(availableRegionId)) || IconMetroMsk16;
}
