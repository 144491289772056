import {
  fetchDeleteOfferFromFavorites,
  TDeleteOfferFromFavoritesResponse,
} from '../../repositories/favorites/v1/delete-offer-from-favorites';
import {
  EDealType,
  EEntityType,
} from '../../repositories/favorites/entities/schemas/DeleteOfferFromFavoritesRequestSchema';
import { IApplicationContext } from '../../types';
import { ResponseError } from '../../errors';

export type TDeleteFromFavoriteParameters = {
  offerId: number;
  dealType: EDealType;
  entityType: EEntityType;
};

export async function deleteFromFavorite(
  context: IApplicationContext,
  parameters: TDeleteFromFavoriteParameters,
): Promise<void> {
  const { logger, httpApi } = context;
  const { offerId, dealType, entityType } = parameters;

  try {
    const response: TDeleteOfferFromFavoritesResponse = await fetchDeleteOfferFromFavorites({
      httpApi,
      parameters: {
        entityId: offerId,
        dealType,
        entityType,
      },
    });

    if (response.statusCode !== 200) {
      /* istanbul ignore next */
      throw new ResponseError({
        domain: 'deleteFromFavorite',
        message: response.response.message || 'Код ответа не 200',
        details: { errors: JSON.stringify(response.response.errors) },
      });
    }
  } catch (error) {
    logger.error(error, {
      offerId,
      domain: 'deleteFromFavorite',
    });

    throw error;
  }
}
