import { IEvent, IConsumer, createConsumer, createSubscriber, getGlobalEventsLogs } from '@cian/events-log';
import { createAppAsyncThunk } from '../utils/redux/createAppAsyncThunk';
import { setIsAuthenticated } from '../slices/authenticationSlice/authenticationSlice';

export interface IRequestAuthenticationParameters {
  onSuccess?(): void;
  onCloseNoAuth?(): void;
}

interface IAuthenticateEvent {
  autoRefresh?: boolean;
}

enum EUserEventTypes {
  Authenticate = 'authenticate',
  Authenticated = 'authenticated',
  AuthenticationModalClosed = 'authentication_modal_closed',
}

const TOPIC_USER = 'user';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};

export const requestAuthentication = createAppAsyncThunk(
  'authentication/requestAuthentication',
  (parameters: IRequestAuthenticationParameters, { dispatch }) => {
    /* istanbul ignore else */
    if (typeof window !== 'undefined') {
      const globalLog = getGlobalEventsLogs();
      const consumer = getUserConsumer();

      const userSubscriber = createSubscriber(async (event: IEvent<unknown>) => {
        if (event.type === EUserEventTypes.Authenticated) {
          dispatch(setIsAuthenticated());

          /* istanbul ignore else */
          if (parameters && parameters.onSuccess) {
            parameters.onSuccess();
          }

          consumer.unsubscribe(userSubscriber);

          return;
        }

        if (event.type === EUserEventTypes.AuthenticationModalClosed) {
          /* istanbul ignore else */
          if (parameters && parameters.onCloseNoAuth) {
            parameters.onCloseNoAuth();
          }

          consumer.unsubscribe(userSubscriber);
        }
      });

      consumer.subscribe(userSubscriber);

      globalLog.produce<Required<IAuthenticateEvent>>(TOPIC_USER, {
        type: EUserEventTypes.Authenticate,
        value: { autoRefresh: false },
      });
    }
  },
);
