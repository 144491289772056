import { createSlice } from '@reduxjs/toolkit';
import { ELandingType } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';

const initialState = ELandingType.MainPage;

const landingTypeSlice = createSlice({
  name: 'landingType',
  reducers: {},
  initialState,
});

export const landingTypeReducer = landingTypeSlice.reducer;
