import { TAddToFavoriteParameters, addToFavorite as _addToFavorite } from '../services/addToFavorite';
import { setCheckedAuthentication, setIsAuthenticated } from '../slices/authenticationSlice';
import { getIsAuthenticated } from '../services/getIsAuthenticated';
import { createAppAsyncThunk } from '../utils/redux/createAppAsyncThunk';
import { TOfferAnalyticsData, TRecommendation } from '../types/recommendation';
import { trackAddToFavorite } from './tracking';
import { addSavedOffer } from '@cian/favorites-assign-widget';
import { EEntityType } from '../repositories/feed-page/entities/recommendations/RecItemV2Schema';
import { toggleFavorite } from '../slices/recommendationsSlice/recommendationsSlice';
import { TDeviceType } from '@cian/ui-kit';

type TAddToFavoriteThunkParameters = {
  deviceType: TDeviceType;
  recommendation: TRecommendation;
};

export const addToFavorite = createAppAsyncThunk(
  'recommendations/addToFavorite',
  async ({ recommendation, deviceType }: TAddToFavoriteThunkParameters, { getState, extra: context, dispatch }) => {
    dispatch(toggleFavorite({ offerId: recommendation.offerId as number, isFavorite: true }));

    const {
      landingType,
      authentication: { isCheckedAuthentication, isAuthenticated },
    } = getState();

    const { offerId, dealType, entityType, analyticsData } = recommendation;

    const [addToFavoriteResult, isAuthenticatedResult] = await Promise.all([
      _addToFavorite(context, { offerId, dealType, entityType } as TAddToFavoriteParameters),
      // Проверка авторизации для подборок. Делается один раз за сеанс для всех рекомендаций
      /* istanbul ignore next */
      isCheckedAuthentication ? isAuthenticated : getIsAuthenticated(context),
    ]);

    dispatch(setCheckedAuthentication());

    if (isAuthenticatedResult && !isAuthenticated) {
      dispatch(setIsAuthenticated());
    }

    const label = `${recommendation.dealType}/${
      recommendation.entityType === EEntityType.OfferSuburban ? 'suburban' : 'flat'
    }`;

    trackAddToFavorite({ deviceType, label, landingType, products: (analyticsData as TOfferAnalyticsData).products });

    const userHasFolders = Boolean(addToFavoriteResult.hasFolders);
    const folder = addToFavoriteResult.folder;
    /* istanbul ignore next */
    const savedInFolders =
      folder && folder.name && folder.id
        ? [
            {
              id: folder.id,
              name: folder.name,
            },
          ]
        : null;

    dispatch(addSavedOffer({ offerIds: [offerId as number], savedInFolders, userHasFolders }));
  },
);
