import { createSlice } from '@reduxjs/toolkit';

const initialState = -1;

const regionIdSlice = createSlice({
  name: 'regionId',
  reducers: {},
  initialState,
});

export const regionIdReducer = regionIdSlice.reducer;
