import { IconFunctionalSuccess12, Label } from '@cian/ui-kit';
import * as React from 'react';
import { LabelsBox } from '../../components/LabelsBox';

interface ILabelsContainerProps {
  visited: boolean;
}

export const LabelsContainer: React.FC<ILabelsContainerProps> = ({ visited }) => {
  const labels = [];

  if (visited) {
    labels.push(
      <Label
        icon={<IconFunctionalSuccess12 color="icon-inverted-default" />}
        background="overlay-default"
        color="text-inverted-default"
      >
        Вы смотрели
      </Label>,
    );
  }

  if (!labels.length) {
    return null;
  }

  return <LabelsBox>{labels}</LabelsBox>;
};
