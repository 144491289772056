import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  fetchCianApiSiteV1AdfoxHome,
  type ICianApiSiteV1AdfoxHomeRequest,
  ICianApiSiteV1AdfoxHomeResponse200,
  ICianApiSiteV1AdfoxHomeResponse400,
  ICianApiSiteV1AdfoxHomeResponse404,
  TCianApiSiteV1AdfoxHomeResponse_1,
} from '../../repositories/monolith-python/cian-api/site/v1/adfox/home';
import { IApplicationContext } from '../../types';
import { ResponseError } from '../../errors';

const domain = 'src/shared/services/fetchAdfoxParams/fetchAdfoxHomeService.ts';

export async function fetchAdfoxHomeService(
  context: IApplicationContext,
  parameters: ICianApiSiteV1AdfoxHomeRequest,
): Promise<TCianApiSiteV1AdfoxHomeResponse_1 | null> {
  const {
    httpApi,
    logger,
    custom: { subdomain },
  } = context;

  try {
    const { statusCode, response } = await fetchCianApiSiteV1AdfoxHome<
      ICianApiSiteV1AdfoxHomeResponse200,
      ICianApiSiteV1AdfoxHomeResponse400,
      ICianApiSiteV1AdfoxHomeResponse404
    >({
      httpApi,
      parameters,
      config: {
        requestConfig: { headers: [['X-NoRedirect', '1']] },
        subdomain,
      },
    });

    if (statusCode !== 200) {
      throw new ResponseError({ domain, message: statusCode.toString() });
    }

    return response;
  } catch (e) {
    if (e instanceof ResponseError || (e instanceof HttpBadStatusCodeError && e.statusCode === 404)) {
      logger.warning(e, {
        domain,
        message: `${e.domain} respond with ${e.message} code`,
      });

      return null;
    }

    logger.error(e, { domain });

    return null;
  }
}
