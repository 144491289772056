import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './RecommendationSkeleton.css';

export const RecommendationSkeleton: React.FC = () => {
  return (
    <div className={styles['container']}>
      <div className={classNames(styles['skeleton'], styles['image'])} />
      <div className={classNames(styles['skeleton'], styles['short'])} />
      <div className={styles['skeleton']} />
      <div className={styles['skeleton']} />
    </div>
  );
};
