/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-218072, будет удалён в задаче CD-218443
 * Если эксперимент будет не удачным, то удалить удалить файл
 * Если эксперимент будет удачным, то удалить комментарий
 */

import * as React from 'react';

import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { TPParams } from '../../../types';
import { AdfoxContextProvider, TAdfoxContextData } from '../../context/AdfoxContext';

const P_PARAMS: TPParams = { p1: 'deels', p2: 'fkhj' };
const ANALYTICS_DATA: TAdfoxContextData['analyticsData'] = {
  banner: {
    name: 'Home_recs_TGB',
    place: 'recs',
  },
};
const SIZE: TAdfoxContextData['size'] = { width: '100%', height: '100%' };

type TAdfoxBannerMainPageOfferMobileProps = {
  position: number;
  onStub?: () => void;
  onError?: () => void;
};

export const AdfoxBannerMainPageOfferMobile: React.FC<TAdfoxBannerMainPageOfferMobileProps> = ({
  position,
  onStub,
  onError,
}) => {
  return (
    <AdfoxContextProvider analyticsData={ANALYTICS_DATA} size={SIZE} pParams={P_PARAMS}>
      <AdfoxBannerContainer position={position} onStub={onStub} onError={onError} />
    </AdfoxContextProvider>
  );
};
