import * as React from 'react';

import { LinkButton, UIHeading2 } from '@cian/ui-kit';
import { useInView } from '@cian/valuation-utils-component';

import * as styles from './RecommendationsList.css';
import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';

export interface IRecommendationsList {
  withHeader: boolean;
  onShow?(): void;
  onMoreClick?(): void;
  children: React.ReactNode;
}

export const RecommendationsList: React.FC<IRecommendationsList> = ({ onShow, withHeader, children, onMoreClick }) => {
  const [isShown, setIsShown] = React.useState(false);
  const { isLoaded: isVisitedOffersLoaded } = useVisitedOffersContext();

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView && onShow && !isShown && isVisitedOffersLoaded) {
      onShow();
      setIsShown(true);
    }
  }, [inView, onShow, isShown, isVisitedOffersLoaded]);

  return (
    <div ref={ref}>
      {withHeader && <UIHeading2>Могут подойти</UIHeading2>}
      <div className={styles['list']}>{children}</div>
      <LinkButton
        data-name="MoreLink"
        size="M"
        theme="fill_secondary"
        fullWidth
        onClick={onMoreClick}
        href="/recommendations/"
      >
        Ещё объявления
      </LinkButton>
    </div>
  );
};
