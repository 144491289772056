import { IApplicationContext } from '../../types';
import {
  TCianApiSiteV1GetUserDataByRequestResponse,
  fetchCianApiSiteV1GetUserDataByRequest,
} from '../../repositories/monolith-python/cian-api/site/v1/get-user-data-by-request';

export async function getIsAuthenticated(context: IApplicationContext): Promise<boolean> {
  const {
    logger,
    httpApi,
    custom: { subdomain },
  } = context;

  try {
    const response: TCianApiSiteV1GetUserDataByRequestResponse = await fetchCianApiSiteV1GetUserDataByRequest({
      httpApi,
      config: {
        subdomain,
      },
    });

    /* istanbul ignore next */
    return !!response.response.data.user?.isAuthenticated;
  } catch (error) {
    logger.error(error, {
      domain: 'getIsAuthenticated',
    });

    throw error;
  }
}
